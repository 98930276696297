<template>
	<div>
		<div class="navigation z-body-small">
			<span
				class="navigation__back"
				@click="$emit('update')"
			>
				{{ $t('siteSettings.forms.title') }}
			</span>
			<ZyroSvg
				name="chevron-right-small"
				class="navigation__chevron"
			/>
			{{ formId === 'contactForm' ? $t('siteSettings.forms.contactForm.title') : $t('siteSettings.forms.subscribeForm.title') }}
		</div>
		<section
			ref="section"
			class="form-submissions"
		>
			<div class="form-submissions__header">
				<h4 class="z-h4">
					{{ $t('common.submissions') }}
				</h4>
				<span class="form-submissions__header-count">
					( <strong> {{ getFormEntries(formId).length }} </strong>
					<span>
						{{ $t('common.items') }}<span>
							)
						</span>
						<ZyroTooltip
							v-if="tableHasRecords"
							position="bottom"
							:use-portal="true"
							class="form-submissions__action-btn"
							data-qa="formsubmissions-btn-exportlist"
							content-position="absolute"
						>
							<template #trigger>
								<ZyroButton
									size="xs"
									icon-dimensions="16px"
									icon-left="download-cloud"
								>
									<ZyroSvg
										name="chevron-down-small"
										icon-dimensions="16px"
									/>
								</ZyroButton>
							</template>
							<ZyroButton data-qa="formsubmissions-btn-exportxls">
								<DownloadExcel
									:fields="exportXlsKeys"
									:data="exportXlsData"
									:name="xlsFileName"
								>
									{{ $t('siteSettings.forms.exportToXLS') }}
								</DownloadExcel>
							</ZyroButton>
							<ZyroButton
								data-qa="formsubmissions-btn-exportcsv"
								@click="exportAsCsv"
							>
								{{ $t('siteSettings.forms.exportToCSV') }}
							</ZyroButton>
							<a
								ref="downloadCsv"
							/>
						</ZyroTooltip>
						<ZyroButton
							v-if="checkboxes.checked.length"
							class="form-submissions__action-btn"
							icon="trash"
							size="xs"
							:title="$t('common.delete')"
							:disabled="!checkboxes.checked.length"
							data-qa="formsubmissions-btn-deleteentries"
							@click="handleDeleteEntries(checkboxes, formId)"
						/>
					</span>
				</span>
			</div>
			<div
				v-if="!tableHasRecords"
				class="form-submissions__no-records"
			>
				<ZyroSvg name="csv-export" />
				<h5 class="z-h5">
					{{ $t('siteSettings.forms.noOneFilled') }}
				</h5>
				<p>
					{{ $t('siteSettings.forms.onceSubmitted') }}
				</p>
			</div>

			<div
				class="form-submissions__table-wrapper"
				:style="computedTableHeight"
			>
				<table
					v-if="tableHasRecords"
					class="form-submissions__table"
				>
					<thead
						ref="header"
						class="form-submissions__table-header"
					>
						<th class="form-submissions__entry-checkbox">
							<ZyroCheckbox
								id="all"
								name="Toggle all"
								:checked="checkboxes.checked.length === getFormEntries(formId).length"
								@input="toggleCheckAll"
							/>
						</th>
						<th
							v-for="tableKey in uniqueTableKeys"
							:key="tableKey"
						>
							{{ tableKey }}
						</th>
						<th
							@click="sortTable('date')"
						>
							<div class="form-submissions__entry-date">
								<span>Date added</span>
								<ZyroSvg
									name="triangle-down"
									:class="{ 'form-submissions__table-sort-icon--flipped': sortedAsc }"
									class="form-submissions__table-sort-icon"
								/>
							</div>
						</th>
						<th class="form-submissions__entry-action" />
					</thead>
					<tbody
						class="form-submissions__table-entries"
					>
						<tr
							v-for="(item, index) in currentPageItems"
							:key="item.id"
							class="form-submissions__table-entry"
						>
							<td class="form-submissions__entry-checkbox">
								<ZyroCheckbox
									:id="item.id"
									name="Toggle selection"
									class="form-submissions__submissions-item-checkbox"
									:checked="checkboxes.checked.includes(item.id)"
									@input="$event.target.checked
										? addCheckedBox(item.id) : removeCheckedBox(item.id)"
								/>
							</td>
							<td
								v-for="(cellItem, cellIndex) in uniqueTableKeys"
								:key="`cell-${cellIndex}`"
							>
								{{ JSON.parse(item.data)[cellItem] || '-' }}
							</td>
							<td class="form-submissions__entry-date">
								{{ formatDate(item.created) }}
							</td>
							<td class="form-submissions__entry-action">
								<ZyroTooltip
									position="bottom"
									:use-portal="true"
									content-position="absolute"
								>
									<template #trigger>
										<ZyroButton
											v-qa="`formsubmissions-tooltip-moreactions${index}`"
											icon="three-dots"
										/>
									</template>
									<ZyroButton
										v-qa="`formsubmissions-tooltip-deleteentry${index}`"
										icon-left="trash"
										@click="deleteEntry({
											dataId: item.id,
											formId
										})"
									>
										{{ $t('common.delete') }}
									</ZyroButton>
								</ZyroTooltip>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<ZyroPagination
				v-if="tableHasRecords"
				class="form-submissions__pagination"
				:current-page="currentPage"
				:page-count="pageCount"
				@change-page="currentPage = $event"
			/>
		</section>
		<div />
	</div>
</template>

<script>
import {
	mapGetters,
	mapActions,
} from 'vuex';

export default {
	props: {
		formId: {
			type: String,
			default: null,
		},
		isRibbonVisible: {
			type: Boolean,
			deafult: false,
		},
	},
	data() {
		return {
			resizeObserver: null,
			elementHeight: 0,
			checkboxes: { checked: [] },
			currentPage: 1,
			elementsPerPage: 10,
			sortedAsc: false,
		};
	},
	computed: {
		...mapGetters('forms', ['getFormEntries']),
		currentPageItems() {
			const paginatedData = this.getFormEntries(this.formId).slice(
				(this.currentPage - 1) * this.elementsPerPage, this.currentPage * this.elementsPerPage,
			);

			return paginatedData;
		},
		pageCount() {
			return Math.ceil(this.getFormEntries(this.formId).length / this.elementsPerPage);
		},
		tableHasRecords() {
			return this.getFormEntries(this.formId).length > 0;
		},
		formEntries() {
			return this.getFormEntries(this.formId).map((entry) => JSON.parse(entry.data));
		},
		uniqueTableKeys() {
			const formEntriesKeys = this.formEntries.map((entry) => Object.keys(entry));

			return [...new Set([...formEntriesKeys.flatMap((item) => item)])];
		},
		computedTableHeight() {
			const substractedRibbonHeight = this.isRibbonVisible ? '290px' : '240px';

			return { maxHeight: `calc(100vh - ${substractedRibbonHeight})` };
		},
		exportXlsData() {
			return this.getFormEntries(this.formId).map(({
				data,
				created,
			}) => ({
				...JSON.parse(data),
				'Date created': this.formatDate(created),
			}));
		},
		exportXlsKeys() {
			return this.uniqueTableKeys.reduce((obj, item) => ({
				...obj,
				[item]: item,
			}), { 'Date created': 'Date created' });
		},
		xlsFileName() {
			const dateFormatter = new Intl.DateTimeFormat('en-us', {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			});

			return `${this.formId}_${dateFormatter.format(new Date())}`;
		},
	},
	async mounted() {
		if (!this.getFormEntries(this.formId).length) {
			await this.fetchFormEntries({ formId: this.formId });
		}
	},
	beforeDestroy() {
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	},
	methods: {
		...mapActions('forms', [
			'fetchFormEntries',
			'deleteEntry',
			'deleteEntries',
		]),
		sortTable(field) {
			if (field === 'date') {
				const getDate = (entry) => new Date(entry.created);

				this.getFormEntries(this.formId).sort((a, b) => (this.sortedAsc ? getDate(a) - getDate(b) : getDate(b) - getDate(a)));
				this.sortedAsc = !this.sortedAsc;
			}
		},
		toggleCheckAll() {
			const checkboxKeys = this.getFormEntries(this.formId).map((listItem) => listItem.id);

			this.checkboxes.checked = this.checkboxes.checked.length < checkboxKeys.length
				? [...checkboxKeys]
				: [];
		},
		addCheckedBox(id) {
			this.checkboxes.checked.push(id);
		},
		removeCheckedBox(id) {
			this.checkboxes.checked.splice(this.checkboxes.checked.indexOf(id), 1);
		},
		handleDeleteEntries(checkboxes, formId) {
			this.deleteEntries({
				checkboxes,
				formId,
			});
			this.checkboxes = { checked: [] };
		},
		exportAsCsv() {
			const formEntries = this.getFormEntries(this.formId);
			// adds dateAdded key value pair to the CSV that's being exported and parses the JSON data
			const modifiedFormEntries = formEntries.map((entry) => {
				const data = {
					...JSON.parse(entry.data),
					dateAdded: new Date(entry.created).toString(),
				};

				return {
					...entry,
					data,
				};
			});

			const formEntriesKeys = modifiedFormEntries.map((entry) => Object.keys(entry.data));
			const keys = [...new Set(formEntriesKeys.flatMap((field) => field))];
			// we need to wrap every entry in quotes so that CSV does not interpret
			const parsedData = modifiedFormEntries.map((listItem) => {
				const modifiedData = Object.fromEntries(Object.entries(listItem.data).map(([key, value]) => [
					key,
					`"${value}"`,
				]));

				return ({
					...listItem,
					data: modifiedData,
				});
			});
			// the semicolon (;) as a delimiter within any field of a submission
			const values = parsedData.map((entryData) => keys.map((key) => entryData.data[key] || '"-"').join(';')).join('\n');
			const csvContent = `data:text/csv;charset=utf-8,${keys.join(';')}\n${values}`;

			this.$refs.downloadCsv.href = encodeURI(csvContent);
			this.$refs.downloadCsv.download = `form_data${new Date().toISOString().toString()}.csv`;
			this.$refs.downloadCsv.click();
		},
		formatDate(date) {
			const formatter = new Intl.DateTimeFormat('en-us', {
				month: 'long',
				day: 'numeric',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
				fractionalSecondDigits: 2,
				hour12: false,
			});

			return formatter.format(new Date(date));
			// "Monday, 12/17/2012, 3:00:42.000 AM"
		},
	},
};
</script>

<style lang="scss" scoped>
.navigation {
	padding-bottom: 20px;

	&__back {
		color: $grey-600;
		cursor: pointer;
	}

	&__chevron {
		width: 6px;
		height: 10px;
		margin: 0 13px;
	}
}

.form-submissions {
	display: flex;
	flex-direction: column;
	height: 100%;
	max-height: 100%;
	padding-bottom: 10px;
	overflow: hidden;

	&__action-btn {
		padding: 0 10px;
		margin-left: 4px;
		border: 1px solid $grey-400;
		border-radius: 5px;
	}

	&__no-records {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		min-height: 430px;
		background: $light;

		h5 {
			margin-top: 38px;
		}

		p {
			margin-top: 16px;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 8px 16px 24px;
		background: $light;
		border-bottom: 1px solid $grey-200;

		&-delete-tooltip {
			margin: 0 17px;
			border: 1px solid $dark;
			transition: 0.25s;

			&:disabled {
				border: 1px solid $grey-600;
			}

			&:hover {
				border-color: $accent-two;
			}
		}

		&-count {
			margin-right: auto;
			margin-left: 13px;
		}
	}

	&__table-wrapper {
		overflow: auto;
	}

	&__table {
		display: table;
		min-width: 100%;
		margin-bottom: 24px;
		table-layout: fixed;
		border-collapse: collapse;
		background: $light;

		th {
			position: sticky;
			top: 0;
			border-bottom: 2px solid $grey-200;
			box-shadow: inset 0 1px $grey-200, 0 1px $grey-200;
		}

		td,
		th {
			max-width: 350px;
			padding: 22px 24px;
			word-wrap: break-word;
			background: $light;
			border-right: 1px solid $grey-200;
		}

		td {
			box-shadow: inset 1px $grey-200;
		}

		tr {
			border-top: 1px solid $grey-200;
		}

		// TODO properly rewrite modifiers

		&-sort-icon {
			margin-right: 0;
			margin-left: 12px;

			&--flipped {
				transform: scaleY(-1);
			}
		}

		&-entries {
			overflow: auto;
		}

		&-entry,
		&-header {
			padding: 24px 8px 16px 24px;
			text-align: left;

			&:hover {
				background: #fdfdfd;
			}
		}
	}

	&__entry {
		&-date {
			min-width: 110px;
		}

		&-checkbox,
		&-action {
			width: 72px;
		}

		&-action {
			text-align: right;

			::v-deep .zyro-button {
				overflow: hidden;
			}
		}
	}

	&__pagination {
		display: flex;
		align-items: center;
		align-self: center;
		margin-top: auto;
		user-select: none;
	}
}
</style>
