<template>
	<div class="form-card">
		<h3 class="z-body z-font-weight-bold form-card__title">
			{{ $t(`siteSettings.forms.${formId}.title`) }}
			<ZyroSvg
				name="bullet-point"
				class="form-card__title-bullet-point"
			/>
			<span class="form-card__submission-count"> {{ entryCount }} {{ $t('common.submissions') }}</span>
		</h3>

		<p class="z-body">
			{{ $t(`siteSettings.forms.${formId}.text`) }}
		</p>
		<ZyroButton
			v-qa="`sitesettings-${$t(`siteSettings.forms.${formId}.title`)}-btn-viewlist`"
			theme="outline"
			class="form-card__button"
			@click="$emit('update', formId)"
		>
			{{ $t('siteSettings.forms.viewList') }}
		</ZyroButton>
	</div>
</template>

<script>
export default {
	props: {
		formId: {
			type: String,
			required: true,
		},
		entryCount: {
			type: Number,
			required: true,
		},
		activeFormId: {
			type: String,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
.form-card {
	flex: 0 0 60%;
	padding: 24px 32px;
	margin-bottom: 16px;
	background: $light;

	@include mobile-view {
		padding: 16px;
		margin-bottom: 8px;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 24px;

		&-bullet-point {
			margin: 0 7px 0 10px;
		}
	}

	&__submission-count {
		color: $grey-600;
	}

	&__button {
		display: block;
		margin-top: 28px;
		margin-left: auto;
	}
}
</style>
