<template>
	<div
		class="no-forms"
		data-qa="sitesettings-forms-noforms"
	>
		<h3 class="z-h5 z-font-weight-light">
			{{ $t('siteSettings.forms.noForms.title') }}
		</h3>
		<p class="z-body">
			{{ $t('siteSettings.forms.noForms.text') }}
		</p>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.no-forms {
	padding: 56px 72px;
	background: $light;

	@include mobile-view {
		padding: 0 16px 32px;
	}
}
</style>
