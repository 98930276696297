<template>
	<section
		class="forms"
		:class="{ 'forms--default-width': !activeFormId }"
	>
		<ZyroSettingsTitle v-if="!activeFormId">
			{{ $t('siteSettings.forms.title') }}
		</ZyroSettingsTitle>
		<div class="forms__container">
			<div
				v-if="!activeFormId"
				class="forms__info"
			>
				<ZyroText>
					<template #title>
						{{ $t('siteSettings.forms.subtitle') }}
					</template>
					<template #text>
						{{ $t('siteSettings.forms.text') }}
					</template>
				</ZyroText>
			</div>
			<div
				class="forms__meta"
			>
				<template
					v-if="activeFormIds.length && !activeFormId"
				>
					<FormCard
						v-for="formId in activeFormIds"
						:key="`${formId}-form-card`"
						:entry-count="getFormEntries(formId).length"
						:form-id="formId"
						@update="activeFormId = $event"
					/>
				</template>
				<NoFormCard v-if="!activeFormIds.length" />
			</div>
		</div>
		<FormsList
			v-if="activeFormId"
			:form-id="activeFormId"
			:is-ribbon-visible="isRibbonVisible"
			@update="activeFormId = $event"
		/>
	</section>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

import ZyroSettingsTitle from '@/components/site-settings/components/ZyroSettingsTitle.vue';
import ZyroText from '@/components/site-settings/components/ZyroText.vue';

import FormCard from './FormCard.vue';
import FormsList from './FormsList.vue';
import NoFormCard from './NoFormCard.vue';

export default {
	name: 'Forms',
	components: {
		ZyroSettingsTitle,
		NoFormCard,
		FormCard,
		FormsList,
		ZyroText,
	},
	props: {
		isRibbonVisible: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return { activeFormId: null };
	},
	computed: {
		...mapState(['website']),
		...mapGetters('forms', [
			'getFormEntries',
			'getForms',
		]),
		activeFormIds() {
			const uniqueActiveFormIds = [...new Set(this.getForms.map((form) => form.formId))];

			return uniqueActiveFormIds;
		},
	},
	async created() {
		this.activeFormIds.forEach((formId) => {
			if (this.getFormEntries(formId).length === 0) {
				this.fetchFormEntries({ formId });
			}
		});
	},
	mounted() {
		this.activeFormId = this.$route.params.formId;
	},
	methods: mapActions('forms', ['fetchFormEntries']),
};
</script>

<style lang="scss" scoped>
.forms {
	&--default-width {
		max-width: 966px;
		margin: 0 auto;
	}

	&__container {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		@include mobile-view {
			flex-direction: column;
		}
	}

	&__info {
		flex: 0 0 40%;
		margin-right: 40px;
	}

	&__meta {
		display: flex;
		flex: 0 0 60%;
		flex-direction: column;
	}
}
</style>
